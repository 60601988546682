<template>
      <v-flex xs12 sm12 md8 lg6 xl6 class="orderWindow">
        <draggable>
          <template slot="header">
            <div class="headerclass" :class="orderType == 'BUY' ? 'buyColor' : 'sellColor'">
                <v-row class="ma-0">
                  <v-col cols="8" class="pa-0">
                  <div class="white--text subtitle-2 pb-1 text-capitalize" >{{priceRangeDetails.Symbol}}</div>
                  <v-radio-group v-if="priceRangeDetails.Exchg == 'NSE' || priceRangeDetails.Exchg == 'BSE'" v-model="radio" row class="orderWindowRadio pa-0 my-0 ml-0" height="24">
                    <v-radio :label="`NSE: ${nseKey}`" value="NSE" class="my-0 ml-0 caption" color="white" height="16" ></v-radio>
                    <v-radio :label="`BSE: ${nseKey}`" value="BSE" class="my-0 ml-0 caption" color="white" height="16"></v-radio>
                  </v-radio-group>
                  <div v-else class="white--text subtitle-2">{{priceRangeDetails.Ltp}}</div>
                </v-col>
                <v-col cols="4" class="pa-0 d-flex align-center justify-end">
                  <v-switch dense  @click="toggleOrderWindow()" inset class="orderSwitch ma-0" color="white" 
                  height="40" ></v-switch>
                  </v-col>
                </v-row>
            </div>
          </template>
          <template slot="main">
            <v-sheet>
            <v-tabs :color="orderType == 'BUY' ? '#2992ec' : '#d13535'" v-model="orderTab" dense max-width="30" class="orderWindowTab" @change="changeOrderTab" >
            <v-tabs-slider :class="orderType == 'BUY' ? 'buyColor' : 'sellColor'"></v-tabs-slider>
              <v-tab :color="orderType == 'BUY' ? '#2992ec' : '#d13535'" :active-class="this.orderType == true ? 'tabSellColor' : 'tabBuyColor'" class="caption"> Regular </v-tab>
              <v-tab :color="orderType == 'BUY' ? '#2992ec' : '#d13535'" :active-class="this.orderType == true ? 'tabSellColor' : 'tabBuyColor'"> Cover </v-tab>
              <v-tab :color="orderType == 'BUY' ? '#2992ec' : '#d13535'" :active-class="this.orderType == true ? 'tabSellColor' : 'tabBuyColor'"> Bracket </v-tab>
              <v-tab :color="orderType == 'BUY' ? '#2992ec' : '#d13535'" :active-class="this.orderType == true ? 'tabSellColor' : 'tabBuyColor'"> AMO </v-tab>
            </v-tabs>
            <v-tabs-items v-model="orderTab" class="px-5 py-2 border-bottom" transition="fade-transition">
                <v-row class="ma-0 pb-3">
                <v-col cols="4" class="pa-0">
                    <v-slide-group v-model="prodType" mandatory class="pa-0" hide-arrows >
                      <v-slide-item v-for="(item, i) in showproductItems" :key="i" v-slot="{ active, toggle }">
                        <v-btn depressed :color="active ? orderType == 'BUY' ? 'buyColor' : 'sellColor' : 'unActiveColor'"
                          height="26" min-width="60" class="caption rounded-sm" :value="item" @click="toggle">
                          {{ item }}
                        </v-btn>
                      </v-slide-item>
                    </v-slide-group>
                  </v-col>
                   <v-col cols="8" class="pa-0 d-flex justify-end">
                    <v-slide-group v-model="priceType" mandatory class="pa-0" hide-arrows>
                      <v-slide-item v-for="(item) in showPriceItems" :key="item" v-slot="{ active, toggle }">
                        <v-btn depressed :color="active ? orderType == 'BUY' ? 'buyColor' : 'sellColor' : 'unActiveColor'" height="26"
                          min-width="60" class="ml-2 caption rounded-sm" :value="item" @click="toggle">
                          {{item == "L"? "Limit": item == "MKT"? "Market": item}} </v-btn>
                      </v-slide-item>
                    </v-slide-group>
                  </v-col>
                  </v-row>
            <v-row class="ma-0">
                <div class=""><label for="" class="pl-2 line-height20 caption primaryColor">Quantity</label>
                  <div>
                  <v-text-field v-model="quantity" height="40" dense type="number" single-line outlined hide-details style="width:150px"></v-text-field>
                  </div>
                </div>
                <div class="pl-7">
                  <label for="" class="pl-2 line-height20 caption primaryColor">Price</label>
                  <div>
                  <v-text-field :disabled='isPrice' v-model="price" height="40" dense type="number" single-line outlined hide-details style="width:150px"></v-text-field>
                  </div>
                </div>
                <div class="pl-7">
                  <label for="" class="pl-2 line-height20 caption primaryColor">Trigger Price</label >
                  <div>
                     <v-text-field :disabled='isTrgPrice' v-model="trgPrice" height="40" dense type="number" single-line outlined hide-details style="width:150px"></v-text-field>
                  </div>
                </div>
                  </v-row>
             
             <v-row class="mx-0 mb-0 mt-2" v-if="orderTab == 2">
                <div class="">
                  <label for="" class="pl-2 line-height20 caption primaryColor">Target</label>
                  <div>
                    <v-text-field v-model="targetPrice" height="40" dense type="number" single-line outlined hide-details style="width:150px"></v-text-field>
                  </div>
                </div>
                <div class="pl-7">
                  <label for="" class="pl-2 line-height20 caption primaryColor">Stoploss</label>
                  <div>
                     <v-text-field v-model="stopLoss" height="40" dense type="number" disabled  single-line outlined hide-details style="width:150px"></v-text-field>
                  </div>
                </div>
                <div class="pl-7">
                  <label for="" class="pl-2 line-height20 caption primaryColor">Trailing Stoploss</label>
                  <div>
                     <v-text-field v-model="trailStopLoss" height="40" dense type="number" single-line outlined hide-details style="width:150px"></v-text-field>
                  </div>
                </div>
             </v-row>
             <v-row class="ma-0 pt-2 pl-2 line-height20 align-center">
              <label class="More options caption" @click="showMore = !showMore">More 
                <span class="chevron bottom"></span></label></v-row>
                 <v-row class="ma-0 d-flex justify-end" v-if="showMore">
                <div class="w-150 d-flex flex-column justify-end">
                  <label for="" class="caption line-height20 ml-1">Validity</label>
                  <v-slide-group v-model="validityType" mandatory class="pa-0" hide-arrows>
                    <v-slide-item v-for="(item, i) in showValidityItems" :key="i" v-slot="{ active, toggle }">
                      <v-btn depressed :color="active ? orderType == 'BUY' ? 'buyColor' : 'sellColor' : 'unActiveColor'"  height="26"
                        min-width="60" class="caption rounded-sm" :value="item" @click="toggle"> {{ item }}</v-btn>
                    </v-slide-item>
                  </v-slide-group>
                </div>
                <div class="pl-7">
                  <label for="" class="pl-2 line-height20 caption primaryColor">Disclosed Qty.</label>
                  <div>
                     <v-text-field height="40" dense type="number" single-line hide-details outlined style="width:150px"></v-text-field>
                  </div>
                </div>
                </v-row>
            </v-tabs-items>
            </v-sheet>
          </template>
          <template slot="footer">
            <v-sheet class="px-4 py-3">
                 <v-row class="ma-0 d-flex justify-end">
                <v-btn @click="placeOrder(orderType)" depressed height="40" width="100" class="body-2 rounded-sm-md text-capitalize"
                  :class="orderType == 'BUY' ? 'buyColor' : 'sellColor'">{{ this.orderType }}</v-btn>
                <v-btn depressed height="40" width="100" class="text-capitalize rounded-sm-md body-2 cancelbtn ml-2" @click="hideOrderWindow" >Cancel</v-btn>
              </v-row>
            </v-sheet>
          </template>
        </draggable>
      </v-flex>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      radio: 'NSE',
      nseKey: "123.45",
      orderTab: 0,
      text: "center",
      prodType: "MIS",
      priceType: "L",
      priceItems: ["L", "MKT", "SL", "SL-M"],
      productItems: ["MIS", "CNC"],
      validityItems: ["Day", "IOC"],
      showproductItems: [],
      showPriceItems: [],
      showValidityItems: [],
      validityType: "Day",
      showMore: false,
      coverOrBracketValidityItems: ["Day"],
      coverOrBracketProductItems: ["MIS"],
      bracketPriceItems: ["L", "SL"],
      coverPriceItems: ["L", "MKT"],
      //input fiels v-model
      quantity:null,
      price:null,
      trgPrice:null,
      targetPrice:null,
      stopLoss:null,
      trailStopLoss:null,
      //input field hide show
      isPrice:true,
      isTrgPrice: true,

    };
  },
  components: {
    draggable: () =>
      import(/* webpackChunkName: "draggable" */ "../views/draggable.vue"),
  },
  computed:{
    ...mapState("marketWatch", ["marketDepthData"]),
    ...mapState("orderWindow", ["showOrderWindow",'orderType','toggleOrder','priceRangeDetails','scriptQuoteDetails','currentOrder']),
  },
  methods: {
    //change tab hide show function
    changeOrderTab() {
      if (this.orderTab == 1) {
        this.showproductItems = this.coverOrBracketProductItems;
        this.showPriceItems = this.coverPriceItems;
        this.showValidityItems = this.coverOrBracketValidityItems;
        this.validityType = "Day";
        this.prodType = "MIS";
      } else if (this.orderTab == 2) {
        this.showproductItems = this.coverOrBracketProductItems;
        this.showPriceItems = this.bracketPriceItems;
        this.showValidityItems = this.coverOrBracketValidityItems;
        this.validityType = "Day";
        this.prodType = "MIS";
      } else {
        this.showproductItems = this.productItems;
        this.showPriceItems = this.priceItems;
        this.showValidityItems = this.validityItems;
      }
    },
    
    //SetValues in using priceRange orderWindow
    setDefaultValues() {
      // this.price = this.priceRangeDetails.Ltp
      // this.quantity = this.priceRangeDetails.
    },

    //Toggle orderWindow
    async toggleOrderWindow() {
      var order = this.orderType == "BUY" ? "SELL" : "BUY"
      await this.$store.commit('orderWindow/setOrderType',order);
    },

    //call orderWindow
    async hideOrderWindow() {
      await this.$store.commit("orderWindow/setOrderWindow", false);
    },

    //order place
    async placeOrder() {
      await this.$store.dispatch("orderWindow/placeOrder", 
      { qty:this.quantity, complexity:this.orderTab, pcode:this.prodType, 
      priceType:this.priceType, price:this.price, trgPrice:this.trgPrice});
    }
  },

  // computed:{
  //   priceType: function(){return this.priceType}
  // },

  watch: {
      priceType: function(val) {
        var priceType = val == 0 ? 'limit' : val == 1 ? 'market' : val == 2 ? 'sl' : val == 3 ? 'sl-m' : ''
        console.log(priceType)
        if(priceType == 'limit') {
          this.isPrice = true
          this.isTrgPrice = false
        }else if(priceType == 'market'){
          this.isPrice = false
          this.isTrgPrice = false
        }
        console.log(this.isPrice,'isPrice')
        console.log(this.isTrgPrice,'isTrgPrice')
      }
  },

  mounted() {
    this.changeOrderTab();
  },
};
</script>

<style>
.headerclass {cursor: move;padding: 12px 18px !important;background: #2992ec;height: 72px;width: 550px;border-radius: 3px 3px 0 0 !important;}
.orderWindowRadio .v-icon.v-icon {font-size: 16px !important;color: #ffffff !important;}
.orderWindowRadio .v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {font-size: 12px !important;} 
.orderWindowRadio .v-input--selection-controls__input{width: 16px !important;height: 16px !important;}
.orderWindowRadio .v-input--selection-controls__input{margin-right: 4px !important;}
.orderWindowRadio .v-input--radio-group.v-input--radio-group--row .v-radio{margin-right: 12px !important;}
.orderWindowRadio .theme--light.v-label{color: #ffffff !important;}
.orderWindowTab .v-tabs-bar {height: 38px !important;}
.orderWindowTab .v-tab {font-size: 12px !important;text-transform: inherit !important;font-weight: normal !important;letter-spacing: inherit !important;}
.prodTypeBtn {padding: 5px 8px;border-radius: 2px;font-size: 11px;width: 60px;height: 26px;text-align: center;color: #282828 !important;background-color: #e6e6e6;cursor: pointer;}
.line-height20{line-height:20px !important}
.line-height24{line-height:24px !important}
.buyColor {background-color: #2992ec !important;}
.sellColor {background-color: #d13535 !important;}
.unActiveColor {background-color: #e6e6e6 !important;}
.v-btn.buyColor,.v-btn.sellColor {color: #ffffff !important;text-transform: inherit !important;}
.v-btn:not(.v-btn--outlined).unActiveColor {color: #282828 !important;text-transform: inherit !important;}
.v-application--is-ltr .v-slide-group__content > .v-btn.v-btn:nth-child(2) {margin-left: 8px !important;}
.border-a8a8a8 {border: solid 1px #a8a8a8;}
.orderField {width: 150px;height: 40px;outline: none !important;box-shadow: none !important;}
.chevron::before {border-style: solid;border-width: 0.1em 0.1em 0 0 !important;content: "";display: inline-block;height: 8px;left: 2px;position: relative;
 top: 2px;transform: rotate(-225deg); vertical-align: top;width: 8px;margin-top: 4px;}
.chevron.bottom:before {top: 0;transform: rotate(135deg);}
.orderWindow .v-btn{letter-spacing: 0!important;}
.v-input--selection-controls__ripple {height: 0px !important;}
.v-input--switch.v-input--dense.v-input--switch--inset .v-input--switch__track {height: 16px !important;width: 38px !important;top: calc(50% - 12px);left: -3px;}
.v-input--switch.v-input--dense .v-input--switch__thumb { width: 12px !important; height: 12px !important;}
</style>